.rc-menu-item-active,
.rc-menu-submenu-active > .rc-menu-submenu-title {
  background-color:#8E793E !important;
}
.rc-menu-item-selected {
  background-color:#8E793E !important;
  transform: translateZ(0);
}
.rc-menu-submenu-selected {
  background-color:#8E793E !important;
}

.rc-menu-horizontal > .rc-menu-submenu-active,
.rc-menu-horizontal > .rc-menu-item-active {
  border-bottom: 2px solid #f3f5f7 !important;
  background-color:#8E793E !important;
  color: #f3f5f7 !important;
}